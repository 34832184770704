<template>
    <form-field
        v-click-outside="onBlur"
        :shrink="props.shrink"
    >
        <label
            v-if="props.label"
            :for="props.id"
            class="form-label"
        >
            {{ props.label }}
            <span
                v-if="props.required"
                class="text-red-600"
            >*</span>
        </label>

        <div class="relative flex items-center">
            <slot name="before-input" />

            <div
                :id="props.id"
                ref="input"
                class="form-input whitespace-pre-wrap"
                :class="{ 'form-input--icon': props.icon }"
                :contenteditable="!props.disabled"
                v-bind="$attrs"
                @blur="onBlur"
                @change="emits('change', $event)"
                @focus="onFocus"
                @keydown="onKeydown"
                @input="onInput"
            />

            <Loading
                v-if="props.loading"
                color="media"
                class="absolute align-y left-4 pointer-events-none"
            />

            <font-awesome-icon
                v-else-if="props.icon"
                :icon="props.icon"
                class="absolute top-3 left-4 opacity-50 pointer-events-none"
            />

            <slot
                :focused="focused"
                :on-focus="onFocus"
                :on-blur="onBlur"
            />
        </div>

        <p
            v-if="props.instructions"
            class="form-instructions"
        >
            {{ props.instructions }}
        </p>

        <form-field-errors :errors="errors" />
    </form-field>
</template>

<script setup lang="ts">
    import type { Icon } from '~/types';

    export interface Props {
        id: string;
        label?: string;
        instructions?: string;
        icon?: Icon;
        disabled?: boolean;
        required?: boolean;
        loading?: boolean;
        errors?: string[] | string;
        shrink?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        label: undefined,
        instructions: undefined,
        icon: undefined,
        disabled: false,
        required: false,
        loading: false,
        errors: undefined,
        shrink: false,
    });

    const emits = defineEmits([ 'focus', 'blur', 'keydown', 'change' ]);

    const modelValue = defineModel<any>();

    const input = ref<HTMLElement | null>(null);
    const focused = ref<boolean>(false);

    const onFocus = (event: FocusEvent) => {
        focused.value = true;
        emits('focus', event);
    };

    const onKeydown = (event: KeyboardEvent) => {
        focused.value = true;
        emits('keydown', event);
    };

    const onBlur = (event: FocusEvent) => {
        focused.value = false;
        emits('blur', event);
    };

    const onInput = (event: Event) => {
        const target = event.target as HTMLElement;
        modelValue.value = target.innerText.replace('\n\n', '\n');
    };

    const onPaste = (event: ClipboardEvent) => {
        event.preventDefault();

        const text = event.clipboardData?.getData('text/plain') || '';

        document.execCommand('insertHTML', false, text);
    };

    watch(modelValue, newValue => {
        const currentValue = input.value?.innerText.replace('\n\n', '\n');

        if (currentValue !== newValue) {
            input.value!.innerText = newValue ?? '';
        }
    });

    onMounted(() => {
        input.value!.innerText = modelValue.value ?? '';
        input.value!.addEventListener('paste', onPaste);
    });

    onBeforeUnmount(() => {
        input.value!.removeEventListener('paste', onPaste);
    });
</script>
